<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>501c3 Funding</h1>
      </div>
    </div>
    <!-- Student Athlete Payment totals -->
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                <h5 class="card-category">
                  {{
                    transactionTotalsCategories[
                      donationFundingLineGraph.activeIndex
                    ]
                  }}
                </h5>
                <h2 class="card-title">
                  501c3 Funding -
                  {{
                    transactionTotalsCategories[
                      donationFundingLineGraph.activeIndex
                    ]
                  }}
                </h2>
              </div>
              <div class="col-sm-6">
                <div
                  class="btn-group btn-group-toggle"
                  :class="isRTL ? 'float-left' : 'float-right'"
                  data-toggle="buttons"
                >
                  <label
                    v-for="(option, index) in transactionTotalsCategories"
                    :key="option"
                    class="btn btn-sm btn-primary btn-simple"
                    :class="{
                      active: donationFundingLineGraph.activeIndex === index,
                    }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initTotalTransactionsBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="donationFundingLineGraph.activeIndex === index"
                    />
                    {{ option }}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              ref="bigChart"
              chart-id="big-line-chart"
              :chart-data="donationFundingLineGraph.chartData"
              :gradient-colors="donationFundingLineGraph.gradientColors"
              :gradient-stops="donationFundingLineGraph.gradientStops"
              :extra-options="donationFundingLineGraph.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>
    </div>
    <!-- Pie Charts -->
    <div class="row">
      <div class="col-6 mr-auto">
        <card class="card-chart card-chart-pie">
          <h5 slot="header" class="card-category">Funding By Sport (Top 3)</h5>

          <div class="row">
            <div class="col-6">
              <div class="chart-area">
                <pie-chart
                  :chart-data="pieChart2.chartData"
                  :extra-options="pieChart2.extraOptions"
                  :height="240"
                >
                </pie-chart>
              </div>
            </div>

            <div class="col-6">
              <h4 class="card-title">
                <i class="tim-icons icon-money-coins text-success"></i>
                {{ Math.round(Number(topThreeSports)) | currency }}
              </h4>
              <p class="category">Funding from top 3 sports</p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-6 ml-auto">
        <card class="card-chart card-chart-pie">
          <h5 slot="header" class="card-category">General Funding</h5>

          <div class="row">
            <div class="col-6">
              <div class="chart-area">
                <pie-chart
                  :chart-data="pieChart1.chartData"
                  :extra-options="pieChart1.extraOptions"
                  :height="217"
                >
                </pie-chart>
              </div>
            </div>

            <div class="col-6">
              <h4 class="card-title">
                <i class="tim-icons icon-money-coins text-success"></i>
                Breakdown by Gender
              </h4>
              <p class="category">
                Men: ${{
                  Math.round(Number(pieChart1.chartData.datasets[0].data[0]))
                    | currency
                }}
              </p>
              <p class="category">
                Women: ${{
                  Math.round(Number(pieChart1.chartData.datasets[0].data[1]))
                    | currency
                }}
              </p>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!-- Football, Mens Basketball, Womans Basketball -->
    <div class="row">
      <div class="col-lg-4" :class="{ 'text-right': isRTL }">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Total Football</h5>
            <h3 class="card-title">
              <i
                class="fas fa-football-ball"
                style="color: rgb(111, 48, 48)"
              ></i>
              ${{ Math.round(Number(totalFootballTransactions)) | currency }}
            </h3>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              chart-id="purple-line-chart"
              :chart-data="totalFootballTransactionsChart.chartData"
              :gradient-colors="totalFootballTransactionsChart.gradientColors"
              :gradient-stops="totalFootballTransactionsChart.gradientStops"
              :extra-options="totalFootballTransactionsChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>
      <div class="col-lg-4" :class="{ 'text-right': isRTL }">
        <!-- <card type="chart">
              <template slot="header">
                <h5 class="card-category">{{ $t("dashboard.dailySales") }}</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-delivery-fast text-info"></i> 3,500€
                </h3>
              </template>
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  chart-id="blue-bar-chart"
                  :chart-data="blueBarChart.chartData"
                  :gradient-stops="blueBarChart.gradientStops"
                  :extra-options="blueBarChart.extraOptions"
                >
                </bar-chart>
              </div>
            </card> -->
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Total Mens Basketball</h5>
            <h3 class="card-title">
              <i
                class="fas fa-basketball-ball"
                style="color: rgb(196, 85, 16)"
              ></i>
              ${{
                Math.round(Number(totalMensBasketballTransactions)) | currency
              }}
            </h3>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              chart-id="blue-line-chart"
              :chart-data="totalMensBasketballTransactionsChart.chartData"
              :gradient-colors="
                totalMensBasketballTransactionsChart.gradientColors
              "
              :gradient-stops="
                totalMensBasketballTransactionsChart.gradientStops
              "
              :extra-options="totalMensBasketballTransactionsChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>
      <div class="col-lg-4" :class="{ 'text-right': isRTL }">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Total Womans Basketball</h5>
            <h3 class="card-title">
              <i class="fas fa-basketball-ball" style="color: pink"></i> ${{
                Math.round(Number(totalWomansBasketballTransactions)) | currency
              }}
            </h3>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              chart-id="green-line-chart"
              :chart-data="totalWomansBasketballTransactionsChart.chartData"
              :gradient-stops="
                totalWomansBasketballTransactionsChart.gradientStops
              "
              :extra-options="
                totalWomansBasketballTransactionsChart.extraOptions
              "
            >
            </line-chart>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4" :class="{ 'text-right': isRTL }">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Total Baseball</h5>
            <h3 class="card-title">
              <i class="fas fa-baseball-ball"></i>
              ${{ Math.round(Number(totalBaseballTransactions)) | currency }}
            </h3>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              chart-id="purple-line-chart"
              :chart-data="totalBaseballTransactionsChart.chartData"
              :gradient-colors="totalBaseballTransactionsChart.gradientColors"
              :gradient-stops="totalBaseballTransactionsChart.gradientStops"
              :extra-options="totalBaseballTransactionsChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>

      <div class="col-lg-4" :class="{ 'text-right': isRTL }">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Total Softball</h5>
            <h3 class="card-title">
              <i
                class="fas fa-baseball-ball"
                style="color: rgb(160, 224, 11)"
              ></i>
              ${{ Math.round(Number(totalSoftballTransactions)) | currency }}
            </h3>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              chart-id="purple-line-chart"
              :chart-data="totalSoftballTransactionsChart.chartData"
              :gradient-colors="totalSoftballTransactionsChart.gradientColors"
              :gradient-stops="totalSoftballTransactionsChart.gradientStops"
              :extra-options="totalSoftballTransactionsChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>
      <!-- Mens Lacross -->
      <div class="col-lg-4" :class="{ 'text-right': isRTL }">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Total Mens Hockey</h5>
            <h3 class="card-title">
              <i class="fas fa-hockey-puck"></i>
              ${{ Math.round(Number(totalHockeyTransactions)) | currency }}
            </h3>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              chart-id="purple-line-chart"
              :chart-data="totalMensHockeyTransactionsChart.chartData"
              :gradient-colors="totalMensHockeyTransactionsChart.gradientColors"
              :gradient-stops="totalMensHockeyTransactionsChart.gradientStops"
              :extra-options="totalMensHockeyTransactionsChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import PieChart from "@/components/Charts/PieChart";
// import BarChart from "@/components/Charts/BarChart";
import * as chartConfigs from "@/components/Charts/config";
// import TaskList from "./Dashboard/TaskList";
// import UserTable from "./Dashboard/UserTable";
import config from "@/config";
import axios from "axios";

export default {
  components: {
    LineChart,
    PieChart,
    // BarChart,
  },
  data() {
    return {
      totalBreakdownBySport: [],
      totalBreakdownBySchool: [],
      saTransactions: [],
      monthlyGeneralFunding: [],
      monthlyGeneralFundingBySport: [],
      countedData: [],
      footballTotals: [],
      mensBasketballTotals: [],
      womansBasketballTotals: [],
      baseballTotals: [],
      softballTotals: [],
      mensHockeyTotals: [],
      pieChart1: {
        chartData: {
          labels: ["Men", "Woman"],
          datasets: [
            {
              label: "Mens Vs Women",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ["#2D0EF2", "#F20E8F"],
              borderWidth: 0,
              data: [100, 40],
            },
          ],
        },
        extraOptions: chartConfigs.pieChartOptions,
      },
      pieChart2: {
        chartData: {
          labels: [1, 2, 3],
          datasets: [
            {
              label: "Emails",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ["#ff8779", "#2a84e9", "#e2e2e2"],
              borderWidth: 0,
              data: [60, 40, 20],
            },
          ],
        },
        extraOptions: chartConfigs.pieChartOptions,
      },
      donationFundingLineGraph: {
        allData: [
          [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
          [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
          [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [{}],
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
        },
        extraOptions: chartConfigs.purpleChartOptionsNotMoney,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: [],
      },
      totalFootballTransactionsChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0],
      },
      totalMensBasketballTransactionsChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0],
      },
      totalWomansBasketballTransactionsChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0],
      },
      totalSoftballTransactionsChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0],
      },
      totalBaseballTransactionsChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0],
      },
      totalMensHockeyTransactionsChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0],
      },
      blueBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ["USA", "GER", "AUS", "UK", "RO", "BR"],
          datasets: [
            {
              label: "Countries",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    topThreeSports() {
      const total = this.pieChart2.chartData.datasets[0].data.reduce(
        (accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue);
        },
        0
      );
      return total.toString();
    },
    transactionTotalsCategories() {
      return ["Total Transactions", "Payment Totals"];
    },
    totalFootballTransactions() {
      const total = this.footballTotals.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue);
      }, 0);
      return total.toString();
    },
    totalMensBasketballTransactions() {
      const total = this.mensBasketballTotals.reduce(
        (accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue);
        },
        0
      );
      return total.toString();
    },
    totalWomansBasketballTransactions() {
      const total = this.womansBasketballTotals.reduce(
        (accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue);
        },
        0
      );
      return total.toString();
    },
    totalBaseballTransactions() {
      const total = this.baseballTotals.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue);
      }, 0);
      return total.toString();
    },
    totalSoftballTransactions() {
      const total = this.softballTotals.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue);
      }, 0);
      return total.toString();
    },
    totalHockeyTransactions() {
      const total = this.mensHockeyTotals.reduce(
        (accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue);
        },
        0
      );
      return total.toString();
    },
    totalPaymentsMade() {
      const total = this.pieChart1.chartData.datasets[0].data.reduce(
        (accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue);
        },
        0
      );
      return total.toString();
    },
  },
  async mounted() {
    await this.initiateData();
    this.initDashboardData();

    this.initTotalTransactionsBigChart(0);
    this.initFootballTotals();
    this.initMensBasketballChart();
    this.initWomansBasketballChart();
    this.initBaseballChart();
    this.initSoftballChart();
    this.initMensHockeyChart();
    this.initPieChartsTotal();
  },
  methods: {
    async initiateData() {
      await this.$http
        .get(
          `${process.env.VUE_APP_BASE_URL}/api/donation-funding-report-dashboard`
        )
        .then((res) => {
          const totalMonthlyTransaction = res.data.transactions_by_month;
          const totalMonthlyTransactionsBySport =
            res.data.transactions_by_sport;

          this.monthlyGeneralFunding = totalMonthlyTransaction;
          this.monthlyGeneralFundingBySport = totalMonthlyTransactionsBySport;
          this.totalBreakdownBySport = res.data.total_transactions;
          this.totalBreakdownBySchool = res.data.total_transactions_by_school;
        })
        .catch((err) => console.log(err));
    },
    initFootballTotals() {
      let chartData = {
        datasets: [
          {
            label: "Data",
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.footballTotals,
          },
        ],
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
      };

      this.totalFootballTransactionsChart.chartData = chartData;
    },
    initMensBasketballChart() {
      let chartData = {
        datasets: [
          {
            label: "Data",
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.mensBasketballTotals,
          },
        ],
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
      };
      this.totalMensBasketballTransactionsChart.chartData = chartData;
    },
    initWomansBasketballChart() {
      this.initBaseballChart();
      let chartData = {
        datasets: [
          {
            label: "Data",
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.womansBasketballTotals,
          },
        ],
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
      };
      this.totalWomansBasketballTransactionsChart.chartData = chartData;
    },
    initBaseballChart() {
      let chartData = {
        datasets: [
          {
            label: "Data",
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.baseballTotals,
          },
        ],
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
      };
      this.totalBaseballTransactionsChart.chartData = chartData;
    },
    initSoftballChart() {
      let chartData = {
        datasets: [
          {
            label: "Data",
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.softballTotals,
          },
        ],
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
      };
      this.totalSoftballTransactionsChart.chartData = chartData;
    },
    initMensHockeyChart() {
      let chartData = {
        datasets: [
          {
            label: "Data",
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.mensHockeyTotals,
          },
        ],
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
      };
      this.totalMensHockeyTransactionsChart.chartData = chartData;
    },
    initPieChartsTotal() {
      // Top Three
      const topThreeSports = this.totalBreakdownBySport.slice(0, 3);
      const sportCountLabelsTopThree = topThreeSports.map((item) => item.sport);
      const sportCountDataTopThree = topThreeSports.map(
        (item) => item.total_amount
      );

      let chartDataOne = {
        labels: sportCountLabelsTopThree,
        datasets: [
          {
            label: "Totals",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ["#9C67A5", "#E57459", "#72B45C"],
            borderWidth: 0,
            data: sportCountDataTopThree,
          },
        ],
      };

      this.pieChart2.chartData = chartDataOne;

      // Mens VS womans
      let mensSum = 0;
      let womansSum = 0;
      this.totalBreakdownBySport.forEach((item) => {
        if (item.sport.charAt(0) === "W") {
          womansSum += parseFloat(item.total_amount);
        } else {
          mensSum += parseFloat(item.total_amount);
        }
      });
      let chartDataTwo = {
        labels: ["Men", "Woman"],
        datasets: [
          {
            label: "Mens Vs Women",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ["#2D0EF2", "#F20E8F"],
            borderWidth: 0,
            data: [Math.round(Number(mensSum)), Math.round(Number(womansSum))],
          },
        ],
      };
      this.pieChart1.chartData = chartDataTwo;
    },
    initTotalTransactionsBigChart(index) {
      // reduce the data from monthlyGeneralFunding
      const totalMonthlyAmounts = this.monthlyGeneralFunding.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.total_amount);
          return accumulator;
        },
        []
      );

      const totalMonthlyTransactions = this.monthlyGeneralFunding.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.total_transactions);
          return accumulator;
        },
        []
      );

      this.countedData.push(totalMonthlyTransactions);
      this.countedData.push(totalMonthlyAmounts);

      // console.log(this.countedData);

      let chartData = {
        datasets: [
          {
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // Data is changed here
            data: this.countedData[index],
          },
        ],
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
      };
      this.$refs.bigChart.updateGradients(chartData);
      this.donationFundingLineGraph.chartData = chartData;
      this.donationFundingLineGraph.activeIndex = index;
    },

    transactionTotalsBySport(sport) {
      const data = this.monthlyGeneralFundingBySport.reduce(
        (accumulator, currentValue) => {
          if (currentValue.sport.toLowerCase() === sport.toLowerCase()) {
            accumulator.push(currentValue.total_amount);
          }
          return accumulator;
        },
        []
      );
      return data;
    },
    async initDashboardData() {
      this.footballTotals = this.transactionTotalsBySport("Football");
      this.mensBasketballTotals = this.transactionTotalsBySport("MBBALL");
      this.womansBasketballTotals = this.transactionTotalsBySport("WBBALL");
      this.baseballTotals = this.transactionTotalsBySport("BASE");
      this.softballTotals = this.transactionTotalsBySport("SOFT");
      this.mensHockeyTotals = this.transactionTotalsBySport("MHOCK");
    },
    countTransactionTotals(data) {
      const totals = data.reduce((acc, item) => {
        const eventDate = new Date(item.event_date);
        const year = eventDate.getFullYear();
        const month = (eventDate.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0 for January
        const key = `${year}-${month}`; // Format as YYYY-MM

        if (!acc[key]) {
          acc[key] = 0;
        }

        acc[key] += Math.round(this.parseAmount(item.amount1));
        return acc;
      }, {});

      return Object.values(totals);
    },
    countMonthlyTransactions(data) {
      const countMap = data.reduce((acc, item) => {
        const eventDate = new Date(item.event_date);
        const month = eventDate.getMonth() + 1; // getMonth() returns 0 for January
        const year = eventDate.getFullYear();
        const key = `${year}-${month.toString().padStart(2, "0")}`; // Format as YYYY-MM

        if (!acc[key]) {
          acc[key] = 0;
        }
        acc[key]++;
        return acc;
      }, {});

      return Object.values(countMap);
    },
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = "en";
      this.$rtl.disableRTL();
    }
  },
};
</script>
<style></style>
