<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>{{ athlete.athlete_name }}</h1>
      </div>
    </div>
    <!-- Athlete Information Highlight Section -->
    <div class="row justify-content-center">
      <div class="col-md-6">
        <card type="testimonial" header-classes="card-header-avatar">
          <a slot="header" :href="schoolLink">
            <img class="img img-raised" :src="schoolLogo" alt="Card image" />
          </a>

          <p class="card-description">
            {{ athlete.athlete_name }} is part of the {{ formatSport }} program
            at {{ athlete.orginization }} where they have made
            <span class="text-success">
              {{ yearlySalary }}
            </span>
            in {{ new Date().getFullYear() }}
          </p>
          <div
            :class="{
              'icon icon-male': athlete.gender === 'Male',
              'icon icon-female': athlete.gender !== 'male',
            }"
          >
            <i class="tim-icons icon-single-02"></i>
          </div>

          <template slot="footer">
            <h4 class="card-title">
              {{ athlete.athlete_name }} - {{ formatSport }}
            </h4>
            <p class="category">{{ athlete.orginization }}</p>
          </template>
        </card>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-6">
        <stats-card
          :title="totalCommercial"
          sub-title="Commercial Payments"
          type="info"
          icon="tim-icons icon-credit-card"
        >
          <div slot="footer" v-html="latestUpdate"></div>
        </stats-card>
      </div>
      <div class="col-lg-3 col-md-6">
        <stats-card
          :title="totalDonation"
          sub-title="Donation Payments"
          type="warning"
          icon="tim-icons icon-gift-2"
        >
          <div slot="footer" v-html="latestUpdate"></div>
        </stats-card>
      </div>
      <div class="col-lg-3 col-md-6">
        <stats-card
          :title="totalEngagements"
          sub-title="Total Engagements"
          type="primary"
          icon="tim-icons icon-vector"
        >
          <div slot="footer" v-html="latestUpdate"></div>
        </stats-card>
      </div>
    </div>

    <!-- Transactions Table -->
    <div>
      <StudentAthleteDataTable
        :athleteTransactions="transactions"
        :athleteInformation="athlete"
        v-if="loaded"
      />
    </div>
  </div>
</template>

<script>
import StudentAthleteDataTable from "./StudentAthleteDataTable.vue";
import StatsCard from "src/components/Cards/StatsCard";

export default {
  components: {
    StudentAthleteDataTable,
    StatsCard,
  },
  data() {
    return {
      loaded: false,
      transactions: [],
      athlete: {},
      transactionTotal: "",
    };
  },
  async mounted() {
    await this.initPage();
  },
  methods: {
    async initPage() {
      const id = this.$route.params.athleteName;
      await this.$http
        .get(
          `${process.env.VUE_APP_BASE_URL}/api/student-athlete-transactions/student/${id}`
        )
        .then((res) => {
          console.log(res.data);
          this.transactions = res.data.transactions;
          this.athlete = res.data.athlete;
          this.loaded = true;
        })
        .catch((err) => console.log(err));
    },
    formatCurrency(value) {
      return (
        "$ " +
        Number(value)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
  },
  computed: {
    // Assuming each page will only display transactions for a single athlete
    athleteName() {
      return this.transactions.length ? this.transactions[0].athlete_name : "";
    },
    transaction() {
      // Displaying the first transaction's details as representative athlete info
      return this.transactions.length ? this.transactions[0] : {};
    },
    totalCommercial() {
      return this.formatCurrency(this.athlete.total_commercial);
    },
    totalDonation() {
      return this.formatCurrency(this.athlete.total_donation);
    },
    latestUpdate() {
      return `<i class="tim-icons icon-refresh-01"></i> last updated on - ${this.athlete.latest_update}`;
    },
    schoolLogo() {
      return `/img/school_logos/${this.athlete.orginization_abbreviation}-logo.jpeg`;
    },
    schoolLink() {
      return `/orginization/${this.athlete.orginization_abbreviation}`;
    },
    formatSport() {
      const sportMapping = {
        Football: "Football",
        FOOTBALL: "Football",
        MBBALL: "Mens Basketball",
        WBBALL: "Womens Basketball",
        BASE: "Baseball",
        MGOLF: "Mens Golf",
        MHOCK: "Mens Hockey",
        WLAX: "Womens Lacross",
        MSOCC: "Mens Soccer",
        MLAX: "Mens Lacross",
        MWRES: "Mens Wrestling",
        WVOLL: "Womens Volleyball",
        WTRACK: "Womens Track",
        WSOCC: "Womens Soccer",
        WHOCK: "Womens Hockey",
        WCROSS: "Womens Cross Country",
        MCROSS: "Mens Cross Country",
        SOFT: "Softball",
        WGOLF: "Womens Golf",
        MTENN: "Mens Tennis",
        WSWIM: "Womens Swin",
        WGYM: "Womens Gym",
        TRACK: "Track",
        WFHOCK: "Womens Field Hockey",
        MSWIM: "Mens Swim",
      };
      return sportMapping[this.athlete.sport] || "sport";
    },
    yearlySalary() {
      return this.formatCurrency(
        this.athlete.total_commercial + this.athlete.total_donation
      );
    },
    totalEngagements() {
      return String(this.transactions.length) || "0";
    },
  },
};
</script>

<style>
.icon-female {
  color: hotpink;
}
.icon-male {
  color: blue;
}
.athlete-info-card {
  padding: 20px;
  margin-bottom: 20px;
}
.athlete-info {
  font-size: 16px;
}
.el-table th .cell {
  font-weight: bold;
  font-size: 0.57rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-table th .cell:hover {
  overflow: visible;
  white-space: pre;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  background: #525f7f !important;
}
</style>

<!-- <template>
  <div>
    <h1>Hello There</h1>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    const test = this.$route.params.athleteName;
    console.log(atob(test));
  },
};
</script> -->
