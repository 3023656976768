<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <div class="text-center mb-4">
        <img
          src="https://irp.cdn-website.com/b5f03e19/dms3rep/multi/BPS+Logo-fc11792a.png?dm-skip-opt=true"
          alt=""
          class="logo"
        />
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-login card-white">
            <div>
              <ValidationProvider
                name="password"
                rules="required|min:5"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="password"
                  type="password"
                  placeholder="New Password"
                  addon-left-icon="tim-icons icon-lock-circle"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                name="password"
                rules="required|min:5"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="confirmPassword"
                  placeholder="Confirm Password"
                  addon-left-icon="tim-icons icon-lock-circle"
                  type="password"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>
            </div>

            <div slot="footer">
              <base-button
                native-type="submit"
                type="primary"
                class="mb-3"
                size="lg"
                block
              >
                Create Password
              </base-button>
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";
import axios from "axios";

extend("min", min);
extend("required", required);

export default {
  data() {
    return {
      confirmPassword: "",
      password: "",
      subscribe: true,
      token: "",
    };
  },
  mounted() {
    this.handleLinkInformation();
  },
  methods: {
    handleLinkInformation() {
      const id = this.$route.params.id;
      const token = atob(id);
      this.token = token;
      localStorage.removeItem("is_auth");
      localStorage.removeItem("api_token");
      localStorage.removeItem("schools");
      localStorage.removeItem("isAdmin");
    },
    // This needs to be updated to handle API
    submit() {
      //   const authObj = {
      //     password: this.password,
      //   };
      console.log(this.token);
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/api/auth/password-update`,
          { password: this.password },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((res) => {
          const authResults = {
            authToken: res.data.apiToken,
            schools: res.data.schools,
            isAdmin: res.data.user.is_admin,
          };
          const redirectPath = this.$route.query.redirect || "/";
          this.$router.push(redirectPath);
        })
        .catch((err) => {
          // show the user an error
          this.password = "";
          console.log(err);
        });
    },
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.logo {
  width: 150px;
  height: auto;
}
.card-login .tim-icons {
  color: black !important;
}
</style>
