<template>
  <div class="container">
    <div class="row">
      <div class="col-md-7 mr-auto">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <card class="card-register">
              <ValidationProvider
                name="schoolName"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="schoolName"
                  placeholder="School Name"
                  addon-left-icon="tim-icons icon-badge"
                  type="text"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                name="schoolAbbreviation"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="schoolAbbreviation"
                  placeholder="School Abbreviation"
                  addon-left-icon="tim-icons icon-check-2"
                  type="text"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>
              <base-button
                native-type="submit"
                slot="footer"
                type="primary"
                round
                block
                size="lg"
                @click="createSchool"
              >
                Create School
              </base-button>
            </card>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);

export default {
  data() {
    return {
      schoolName: "",
      schoolAbbreviation: "",
    };
  },
  async mounted() {},
  methods: {
    submit() {},
    createSchool() {
      const authObj = {
        name: this.schoolName,
        abbreviation: this.schoolAbbreviation,
      };
      this.$http
        .post(`${process.env.VUE_APP_BASE_URL}/api/schools/create`, authObj)
        .then((res) => {
          alert(
            `New School Added: ${this.schoolName} - ${this.schoolAbbreviation}`
          );
          this.schoolName = "";
          this.schoolAbbreviation = "";
        })
        .catch((err) => {
          alert("There was an error registering the school");
        });
    },
  },
};
</script>
