<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <div class="text-center mb-4">
        <img
          src="https://irp.cdn-website.com/b5f03e19/dms3rep/multi/BPS+Logo-fc11792a.png?dm-skip-opt=true"
          alt=""
          class="logo"
        />
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-login card-white">
            <div>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="email"
                  type="email"
                  placeholder="Email"
                  addon-left-icon="tim-icons icon-email-85"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                name="password"
                rules="required|min:5"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="password"
                  placeholder="Password"
                  addon-left-icon="tim-icons icon-lock-circle"
                  type="password"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>
            </div>

            <div slot="footer">
              <base-button
                native-type="submit"
                type="primary"
                class="mb-3"
                size="lg"
                block
              >
                Get Started
              </base-button>

              <!-- This should be password reset button -->
              <!-- <div class="pull-right">
                <h6>
                  <a href="#pablo" class="link footer-link">Need Help?</a>
                </h6>
              </div> -->
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
// import authApiService from "@/api/authApiService";
import axios from "axios";

extend("email", email);
extend("min", min);

extend("required", required);

export default {
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
    };
  },
  mounted() {},
  methods: {
    fastLogin() {
      this.$store.dispatch("login").then(() => {
        const redirectPath = this.$route.query.redirect || "/";
        this.$router.push(redirectPath);
      });
    },
    // This needs to be updated to handle API
    submit() {
      const authObj = {
        email: this.email,
        password: this.password,
      };
      // authApiService.login(authObj).then((res) => {
      //   console.log(res.data);
      // });
      this.$http
        .post(`${process.env.VUE_APP_BASE_URL}/api/auth/login`, authObj)
        // axios
        //   .post("http://127.0.0.1:8000/api/auth/login", authObj)
        .then((res) => {
          const authResults = {
            authToken: res.data.apiToken,
            schools: res.data.schools,
            isAdmin: res.data.user.is_admin,
          };
          this.$store.dispatch("login", authResults).then(() => {
            const redirectPath = this.$route.query.redirect || "/";
            this.$router.push(redirectPath);
          });
        })
        .catch((err) => {
          // show the user an error
          this.email = "";
          this.password = "";
          console.log(err);
        });
    },
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.logo {
  width: 150px;
  height: auto;
}
.card-login .tim-icons {
  color: black !important;
}
</style>
